import { Controller } from '@hotwired/stimulus'
import { Tab } from 'bootstrap'
import tifosyCloudinary from 'tifosy_cloudinary'

export default class extends Controller {
  constructor () {
    super(...arguments)

    this.tabInstances = []
  }

  connect () {
    this.initializeAllTabs()
  }

  initializeAllTabs () {
    const triggerTabList = [].slice.call(this.element.querySelectorAll('[data-bs-toggle="tab"]'))
    const self = this // Preserve context

    triggerTabList.forEach(function (triggerEl) {
      const tabInstance = new Tab(triggerEl)
      self.tabInstances.push(tabInstance)

      triggerEl.addEventListener('shown.bs.tab', function (event) {
        tifosyCloudinary.responsive()
      })
    })

    const hash = window.location.hash.trim()
    const triggerEls = [].slice.call(this.element.querySelectorAll(`.nav a.nav-link[href$="${hash}"]`))

    triggerEls.forEach(function (triggerEl) {
      if (triggerEl) {
        const tabInstance = self.tabInstance(triggerEl)
        tabInstance.show()
      }
    })
  }

  openTab (event) {
    const target = event.params.payload.target
    const tab = this.element.querySelector(`#${target}`)

    if (tab) {
      const tabInstance = this.tabInstance(tab)
      tabInstance.show()
    }
  }

  openTabFromSelect (event) {
    const option = event.currentTarget.selectedOptions[0]
    const tabInstance = this.tabInstance(option)

    this.tabInstances.forEach((tab) => {
      tab._deactivate(tab._element)
    })
    tabInstance.show()
  }

  tabInstance (tab) {
    const existingInstance = this.tabInstances.find(instance => instance._element === tab)
    return existingInstance || new Tab(tab)
  }
}
