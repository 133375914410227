import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['otherSelect']
  }

  categorySelect (event) {
    if (event.currentTarget.value === 'unknown') {
      this.otherSelectTarget.classList.remove('hidden')
      this.otherSelectTarget.required = true
      this.otherSelectTarget.focus()
    } else {
      this.otherSelectTarget.classList.add('hidden')
      this.otherSelectTarget.required = false
      this.otherSelectTarget.value = ''
    }
  }
}
