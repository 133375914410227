import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import Smooch from 'smooch'

export default class extends Controller {
  static get targets () {
    return ['raiseData', 'smoochData']
  }

  static get values () {
    return {
      open: { type: Boolean }
    }
  }

  get smoochData () {
    return JSON.parse(this.smoochDataTarget.dataset.smoochData)
  }

  connect () {
    if (this.openValue && process.env.RAILS_ENV !== 'test') {
      this.initializeChat()

      const { autoOpen, desktopDevice, officeHours } = this.smoochData

      if (autoOpen && desktopDevice && officeHours && Cookies.get('auto-chat') === undefined) {
        const events = ['click', 'mousemove', 'keydown']

        for (let i = 0; i < events.length; i++) {
          document.addEventListener(events[i], this.resetTimer.bind(this), false)
        }

        this.resetTimer()
      }
    }
  }

  initializeChat () {
    const data = this.smoochData

    Smooch.init({
      appId: process.env.SMOOCH_APP_ID,
      displayStyle: data.displayStyle,
      customText: {
        headerText: data.headerText,
        introductionText: data.smoochIntroText,
        inputPlaceholder: data.inputPlaceholder,
        sendButtonText: data.sendButtonText
      },
      customColors: {
        brandColor: data.brandColour
      }
    }).then(() => {
      Smooch.updateUser({
        givenName: data.firstName,
        surname: data.lastName,
        email: data.email,
        signedUpAt: data.createdAt,
        properties: {
          monitored: data.data_on,
          userId: data.userId,
          createdOn: data.createdAt,
          fcaCertifiedOn: data.fcaCertifiedOn,
          consobCertifiedOn: data.consobCertifiedOn,
          desktop: data.desktopDevice,
          emailConfirmed: data.confirmedAt,
          fcaInvestorType: data.fcaInvestorType,
          consobInvestorType: data.consobInvestorType,
          applicationsMade: data.applicationsMade,
          investmentsMade: data.investmentsMade,
          ip: data.remoteIp,
          ipCountryCode: data.ipCountryCode,
          platform: data.platform,
          oldBrowser: data.oldBrowser,
          coolingOff: data.coolingOff,
          fcaQuizFailed: data.fcaQuizFailed,
          fcaQuizUnlockAt: data.fcaQuizUnlockAt,
          lastPreReg: data.lastPreReg,
          isaAppliedOn: data.isaAppliedOn
        }
      })

      this.smoochInitialized = true
    })
  }

  toggleSmooch () {
    if (Smooch.isOpened()) {
      Smooch.close()
    } else {
      Smooch.open()
    }
  }

  autoOpenSmooch () {
    if (this.smoochInitialized === true) {
      Smooch.open()
      Cookies.set('auto-chat', 'true', { expires: 3650 })

      if (this.hasRaiseDataTarget) {
        const data = JSON.parse(this.raiseDataTarget.dataset.smoochData)
        window.analytics.track('auto chat opened', data)
      }
    }
  }

  resetTimer () {
    clearTimeout(this.smoochTimer)
    this.smoochTimer = setTimeout(this.autoOpenSmooch.bind(this), 30000)
  }

  disconnect () {
    Smooch.destroy()
  }
};
