import { Controller } from '@hotwired/stimulus'
import { i18n } from 'i18n'
import domainBlocklist from 'validation/domain_blacklist'
const wildcards = require('disposable-email-domains/wildcard.json')

export default class extends Controller {
  get allWildcards () {
    return [...wildcards, ...domainBlocklist]
  }

  validate (event) {
    const domain = event.currentTarget.value.split('@')[1]

    if (this.isDomainBlocklisted(domain)) {
      event.currentTarget.setCustomValidity(i18n.t('errors.messages.undisposable'))
    } else {
      if (event.currentTarget.checkValidity()) {
        event.currentTarget.setCustomValidity('')
      }
    }
  }

  isDomainBlocklisted (domain) {
    if (domain) {
      return this.allWildcards.some((wildcard) => {
        return wildcard.includes(`${domain}`)
      })
    }
  }
}
