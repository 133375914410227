import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['country', 'province', 'provinceSelect', 'text', 'textInput', 'select', 'regionSelect']
  }

  connect () {
    this.chooseRegionControl()
  }

  chooseRegionControl () {
    const alpha3 = this.countryTarget.selectedOptions[0].dataset.alpha3
    this.determineInput(alpha3)
  }

  determineInput (alpha3) {
    if (alpha3 === 'USA') {
      this._showElement(this.selectTarget)
      this._hideElements(this.textTarget, this.provinceTarget)
      this._setSelectValue(this.regionSelectTarget, this.textInputTarget.value.trim())
    } else if (alpha3 === 'ITA') {
      this._showElement(this.provinceTarget)
      this._hideElements(this.textTarget, this.selectTarget)
      this._setSelectValue(this.provinceSelectTarget, this.textInputTarget.value.trim())
    } else {
      this._showElement(this.textTarget)
      this._hideElements(this.provinceTarget, this.selectTarget)
    }
  }

  _setSelectValue (selectTarget, value) {
    if (value.length === 0) return

    if (selectTarget.slimSelectInstance) {
      selectTarget.slimSelectInstance.setSelected(value)
    } else {
      selectTarget.value = value
    }
  }

  _showElement (element) {
    element.classList.remove('hidden')
  }

  _hideElements (...elements) {
    elements.forEach(element => element.classList.add('hidden'))
  }
}
