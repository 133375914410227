import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['distinctId']
  }

  assignAnonId (event) {
    this.distinctIdTarget.value = window.anonId
  }

  track (event) {
    const payload = event.params.payload
    window.analytics.track(payload.name, payload.attributes)
  }
}
