import { i18n } from 'i18n'
import LocalTime from 'local-time'

const initializeLocalTimeConfig = () => {
  LocalTime.config.i18n.it = {
    date: {
      abbrMonthNames: [
        'gen',
        'feb',
        'mar',
        'apr',
        'mag',
        'giu',
        'lug',
        'ago',
        'set',
        'ott',
        'nov',
        'dic'
      ]
    }
  }
  LocalTime.config.locale = i18n.locale
  LocalTime.start()
}

export default initializeLocalTimeConfig
