import { Controller } from '@hotwired/stimulus'
import initializeBar from 'charts/tracked_investments/bar'
import initializeDoughnut from 'charts/tracked_investments/doughnut'
import initializeSummaryChart from 'charts/summary'

export default class extends Controller {
  static get targets () {
    return ['primary', 'secondary']
  }

  static get values () {
    return {
      attributes: Object,
      kind: String
    }
  }

  get chartRenderer () {
    return {
      equity: () => this.renderEquityChart(),
      bond: () => this.renderBondChart(),
      summary: () => this.renderSummaryChart()
    }
  }

  connect () {
    this.chartRenderer[this.kindValue]()
  }

  renderEquityChart () {
    const { principalDataset, scaleMax, dividendDataset } = this.attributesValue

    initializeBar(this.primaryTarget, principalDataset, scaleMax)

    if (dividendDataset.length) {
      initializeBar(this.secondaryTarget, dividendDataset, scaleMax)
    }
  }

  renderBondChart () {
    initializeDoughnut(this.primaryTarget, this.attributesValue)
  }

  renderSummaryChart () {
    initializeSummaryChart(this.primaryTarget, this.attributesValue)
  }
}
