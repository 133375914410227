import { liteClient as algoliasearch } from 'algoliasearch/lite'
import instantsearch from 'instantsearch.js'
import { connectSearchBox } from 'instantsearch.js/es/connectors'
import { hits } from 'instantsearch.js/es/widgets'
import { i18n } from 'i18n'

export const initializeSearch = (platform, locale) => {
  let search

  const applicationId = process.env.ALGOLIASEARCH_APPLICATION_ID
  const apiKey = process.env.ALGOLIASEARCH_API_KEY_SEARCH

  if (applicationId && apiKey) {
    const searchClient = algoliasearch(applicationId, apiKey)

    const indexName = `Article_${platform}_${locale}_${process.env.RAILS_ENV}`

    search = instantsearch({
      indexName,
      searchClient,
      future: {
        preserveSharedStateOnUnmount: false
      }
    })

    const renderSearchBox = (renderOptions, isFirstRender) => {
      const { query, refine, widgetParams } = renderOptions

      if (isFirstRender) {
        const input = document.getElementById('help_search')

        input.addEventListener('input', (event) => {
          refine(event.target.value)
        })
      }

      widgetParams.container.querySelector('input').value = query
    }

    const customSearchBox = connectSearchBox(renderSearchBox)

    let timerId

    search.addWidgets([
      customSearchBox({
        container: document.querySelector('#searchbox'),
        showLoadingIndicator: false,
        queryHook (query, refine) {
          clearTimeout(timerId)
          timerId = setTimeout(() => refine(query), 250)
        }
      }),
      hits({
        container: '#hits',
        transformItems (items, { results }) {
          if (results.query === '') {
            return []
          } else {
            return items
          }
        },

        templates: {
          item: function (data) {
            return `
              <a href='/${document.documentElement.lang}/help/${data.section}/topic/${data.slug}'>
                <div class='smaller-article'>${data._highlightResult.title.value}</div>
              </a>`
          },
          empty: function (results) {
            if (results.query === '') {
              return null
            } else {
              return `<div class='smaller-article p-2'>${i18n.t('help-centre.no-results')}</div>`
            }
          }
        }
      })
    ])
  }

  return search
}
