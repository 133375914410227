let Stripe

export async function getStripe () {
  if (!Stripe) {
    const { loadStripe } = await import('@stripe/stripe-js')

    Stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

    Stripe.createCardElement = function (postalCode) {
      const stripeElements = Stripe.elements({
        fonts: [{ cssSrc: 'https://use.typekit.net/mhp6sdy.css' }],
        locale: document.documentElement.lang
      })

      const style = {
        base: {
          color: '#4a4a4a',
          fontFamily: '"canada-type-gibson", Helvetica, Arial, sans-serif',
          fontSize: '16px',
          fontWeight: 300
        }
      }

      const _cardElement = stripeElements.create('card', {
        style,
        value: {
          postalCode
        }
      })

      return _cardElement
    }
  }

  return Stripe
}
