import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['fieldset', 'form', 'loqateParent', 'text']
  }

  static get values () {
    return {
      company: { type: Boolean, default: false }
    }
  }

  toggleForm ({ params }) {
    if (params.toggle) {
      this.formTarget.classList.remove('hidden')
      this.fieldsetTarget.disabled = this.companyValue

      if (this.hasTextTarget) {
        this.textTarget.classList.add('hidden')
      }

      if (this.hasLoqateParentTarget) {
        this.loqateParentTarget.classList.remove('hidden')
      }
    } else {
      this.formTarget.classList.add('hidden')
      this.fieldsetTarget.disabled = true

      if (this.hasTextTarget) {
        this.textTarget.classList.remove('hidden')
      }

      if (this.hasLoqateParentTarget) {
        this.loqateParentTarget.classList.add('hidden')
      }
    }
  }
};
