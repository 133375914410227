import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['email']
  }

  appendEmail (event) {
    const email = this.emailTarget.value

    if (email) {
      const url = new URL(event.currentTarget.href)
      const params = new URLSearchParams(url.search)
      params.append('email', email)
      url.search = params
      event.currentTarget.href = url
    }
  }
}
