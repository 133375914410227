import Cookies from 'js-cookie'

const tifosyAd = document.getElementById('tifosy-ad')

if (tifosyAd) {
  document.getElementById('advert-close-link').addEventListener('click', () => {
    tifosyAd.classList.toggle('hidden')
    Cookies.set('tifosy-ad', 'true', { expires: 2 })
  })

  if (Cookies.get('tifosy-ad') !== undefined) {
    tifosyAd.classList.toggle('hidden')
  }
}
