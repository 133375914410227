import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import { i18n } from 'i18n'

export default class extends Controller {
  static get targets () {
    return ['error', 'form', 'fileInput', 'filename']
  }

  static get outlets () {
    return ['progress']
  }

  static get values () {
    return { acceptedFileTypes: Array, automaticSubmit: Boolean }
  }

  addFile (event) {
    const file = event.target.files[0]
    this.directFileUpload = new DirectUpload(
      file, '/rails/active_storage/direct_uploads', this
    )
    this.progressOutlet.resetProgressBar()
    this.clearErrors()

    if (this.validateFile()) {
      this.upload(this.successCallback.bind(this))
    } else {
      this.progressOutlet.failProgressBar()
      this.fileInputTarget.value = null
    }
  }

  upload (callback) {
    this.directFileUpload.create((error, blob) => {
      if (error) {
        this.progressOutlet.failProgressBar()
      } else {
        callback(blob.signed_id)
      }
    })
  }

  validateFile () {
    const file = this.directFileUpload.file
    const acceptedFileTypes = this.acceptedFileTypesValue
    const extension = file.name.split('.').pop().toLowerCase()

    if (!acceptedFileTypes.includes(extension)) {
      this.errorTarget.innerHTML =
          i18n.t('forms.edit-profile.document-upload-invalid-filetype', { filename: file.name })
      return false
    } else if (file.size > 10000000) { // 10MB
      this.errorTarget.innerHTML =
          i18n.t('forms.edit-profile.document-upload-too-large', { filename: file.name })
      return false
    } else if (file.size < 20000) { // 20KB
      this.errorTarget.innerHTML =
          i18n.t('forms.edit-profile.document-upload-too-small', { filename: file.name })
      return false
    } else if (this.checkFormValidityOnAutomaticSubmit()) {
      return true
    } else {
      this.errorTarget.innerHTML =
          i18n.t('forms.edit-profile.complete-all-fields')
      return false
    }
  }

  checkFormValidityOnAutomaticSubmit () {
    return !this.automaticSubmitValue || this.element.checkValidity()
  }

  directUploadWillStoreFileWithXHR (request) {
    request.upload.addEventListener('progress', (event) => this.directUploadDidProgress(event))
  }

  directUploadDidProgress (event) {
    this.progressOutlet.updateProgressBar(event.loaded, event.total)
  }

  clearErrors () {
    this.errorTarget.innerHTML = null
  }

  successCallback (signedId) {
    this.progressOutlet.completed()
    const hiddenField = document.createElement('input')
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('value', signedId)
    hiddenField.name = this.fileInputTarget.name
    this.formTarget.appendChild(hiddenField)

    if (this.hasFilenameTarget) {
      this.filenameTarget.innerHTML = this.directFileUpload.file.name
    }

    if (this.automaticSubmitValue) {
      this.formTarget.requestSubmit()
    }
  }
}
