import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['checkbox', 'warning']
  }

  rememberMe () {
    if (this.checkboxTarget.disabled) {
      this.warningTarget.classList.remove('hidden')
    }
  }

  publicRememberMe (event) {
    this.warningTarget.classList.add('hidden')

    const isChecked = event.currentTarget.checked
    this.checkboxTarget.disabled = isChecked

    if (isChecked) {
      this.checkboxTarget.checked = false
    }
  }
}
