/* global Image */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values () {
    return { src: String }
  }

  connect () {
    const image = new Image()
    image.src = this.srcValue
  }
};
