import { Notifier } from '@airbrake/browser'
import airbrakeJsFilters from 'airbrake_filters'

let airbrake = {}

airbrake = new Notifier({
  projectId: 96983,
  projectKey: 'b9f21e39f3f1a0967b22baeee41c3609',
  environment: process.env.RAILS_ENV
})

airbrakeJsFilters.forEach((filter) => airbrake.addFilter(filter))

export default airbrake
