import { Controller } from '@hotwired/stimulus'
import initializeCountdown from 'components/countdown'

export default class extends Controller {
  static get values () {
    return { date: String }
  }

  connect () {
    if (process.env.RAILS_ENV !== 'test') {
      initializeCountdown(this.element, { date: this.dateValue }, this.callback)
    }
  }

  callback () {
    window.location.reload()
  }
}
