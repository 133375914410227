import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values () {
    return { lastIndex: Number }
  }

  static get targets () {
    return ['page', 'step', 'previousTab', 'nextTab']
  }

  connect () {
    this.currentIndex = 0
  }

  showPreviousTab () {
    if (this.currentIndex !== 0) {
      this.hideCurrentStep()
      this.currentIndex -= 1
      this.showCurrentStep()

      this.updateTabLinks()
    }
  }

  showNextTab () {
    if (this.currentIndex !== this.lastIndexValue) {
      this.hideCurrentStep()
      this.currentIndex += 1
      this.showCurrentStep()

      this.updateTabLinks()
    }
  }

  showTab (event) {
    const newTab = parseInt(event.currentTarget.dataset.tab, 10)
    if (this.currentIndex !== newTab) {
      this.hideCurrentStep()
      this.currentIndex = newTab
      this.showCurrentStep()

      this.updateTabLinks()
    }
  }

  hideCurrentStep () {
    this.stepTargets[this.currentIndex].classList.remove('active')
    this.pageTargets[this.currentIndex].classList.add('hidden')
  }

  showCurrentStep () {
    this.stepTargets[this.currentIndex].classList.add('active')
    this.pageTargets[this.currentIndex].classList.remove('hidden')
  }

  updateTabLinks () {
    if (this.currentIndex === 0) {
      this.previousTabTarget.classList.add('inactive')
    } else {
      this.previousTabTarget.classList.remove('inactive')
    }

    if (this.currentIndex === this.lastIndexValue) {
      this.nextTabTarget.classList.add('inactive')
    } else {
      this.nextTabTarget.classList.remove('inactive')
    }
  }
}
