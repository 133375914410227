import '@hotwired/turbo-rails'
import 'polyfills'
import 'airbrake'
import initializeAnalytics from 'analytics'
import initializeCookieControlConfig from 'cookie_control_config'
import 'components/advert'
import mainFooterScrolled from 'components/sticky_nav'
import initializeTrackClick from 'track_click'

import 'lazysizes'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import 'lazysizes/plugins/respimg/ls.respimg'

import initializeLocalTimeConfig from 'local_time_config'
import { supportsCookies } from 'utilities'

import { getStripe } from 'components/stripe'

if (!supportsCookies()) {
  document.getElementById('cookies-disabled-modal').classList.remove('hidden')
}

document.addEventListener('turbo:before-cache', () => {
  const recaptcha = document.querySelector('.g-recaptcha')
  if (recaptcha) {
    recaptcha.innerHTML = ''
  }
})

document.addEventListener('turbo:load', () => {
  initializeTrackClick()

  if (process.env.RAILS_ENV === 'test') {
    document.querySelector('body').classList.add('no-transition')
  } else {
    mainFooterScrolled()

    window.addEventListener('scroll', function () {
      mainFooterScrolled()
    })
  }
})

const application = Application.start()
const context = require.context('controllers', true, /\.js$/)
const contextComponents = require.context('../frontend/components', true, /_controller\.js$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)

initializeLocalTimeConfig()

window.addEventListener('turbo:load', (event) => {
  const analytics = initializeAnalytics()
  initializeCookieControlConfig(analytics)
})

getStripe()
