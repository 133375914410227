import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import smoothscroll from 'smoothscroll-polyfill'

export default class extends Controller {
  static get targets () {
    return ['carousel', 'quicktake']
  }

  static get outlets () {
    return ['modal-dialog']
  }

  connect () {
    smoothscroll.polyfill()
  }

  get currentPosition () {
    return this.carouselTarget.scrollLeft
  }

  get cardWidth () {
    return this.quicktakeTargets[0].offsetWidth
  }

  previousSlide () {
    const position = this.currentPosition - this.cardWidth
    this.scrollToPosition(position)
  }

  nextSlide (event) {
    const position = this.currentPosition + this.cardWidth
    this.scrollToPosition(position)
  }

  scrollToPosition (position) {
    this.carouselTarget.scrollTo({ left: position, behavior: 'smooth' })
  }

  cardClicked (event) {
    event.preventDefault()
    this.showModal(event.currentTarget.href)
  }

  showModal (href) {
    this.modal = new Modal(this.modalDialogOutlet.element)
    this.modal.show()

    this.modalDialogOutlet.renderFromUrl(href)
  }
};
