export const isJsonString = (string) => {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

export const supportsCookies = () => {
  // https://github.com/Modernizr/Modernizr/blob/74655c45ad2cd05c002e4802cdd74cba70310f08/feature-detects/cookies.js
  try {
    document.cookie = 'cookietest=1'
    const ret = document.cookie.indexOf('cookietest=') !== -1
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    return ret
  } catch (e) {
    return false
  }
}
