const _developmentFilter = function (notice) {
  const environment = notice.context.environment

  if (environment === 'staging' || environment === 'production') {
    return notice
  } else {
    console.log(notice)
    return null
  }
}

const _kasperskyFilter = function (notice) {
  // Filters out errors caused by Kaspersky script injection
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('kaspersky') === -1) {
    return notice
  } else {
    return null
  }
}

const _civiccomputingFilter = function (notice) {
  // Filters out errors caused by smooch
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('https://cc.cdn.civiccomputing.com') === -1) {
    return notice
  } else {
    return null
  }
}

const _cookiesDisabledFilter = function (notice) {
  // Filters out errors caused by having cookies disabled
  const message = notice.errors[0].message
  if (message === 'The operation is insecure.') {
    return null
  } else {
    return notice
  }
}

const _facebookSignalsFilter = function (notice) {
  // Filters out errors caused by facebook connect signals script
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('https://connect.facebook.net/signals/') === -1) {
    return notice
  } else {
    return null
  }
}

const _smoochFilter = function (notice) {
  // Filters out errors caused by smooch
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('https://cdn.smooch.io') === -1) {
    return notice
  } else {
    return null
  }
}

const _twitterFilter = function (notice) {
  // Filters out errors caused by twitter platform
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('platform.twitter.com') === -1) {
    return notice
  } else {
    return null
  }
}

const _botFilter = function (notice) {
  // Filters out errors caused by bots
  const userAgent = notice.context.userAgent
  if (userAgent.indexOf('bot') === -1) {
    return notice
  } else {
    return null
  }
}

const _baiduFilter = function (notice) {
  // Filters out errors caused by baidu translation
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('translate.baiducontent.com') === -1) {
    return notice
  } else {
    return null
  }
}

const _googleApiFilter = function (notice) {
  // Filters out errors caused by google platform
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('apis.google.com') === -1) {
    return notice
  } else {
    return null
  }
}

const _parentNodeFilter = function (notice) {
  // Filters out #4173
  const message = notice.errors[0].message
  if (message === 'null is not an object (evaluating \'elt.parentNode\')') {
    return null
  } else {
    return notice
  }
}

const _antiPhishingFilter = function (notice) {
  // Filters out #4680
  const message = notice.errors[0].message
  if (message === 'Unable to get property \'antiPhishingEnabled\' of undefined or null reference') {
    return null
  } else {
    return notice
  }
}

const _securityFilter = function (notice) {
  // Filters out same origin SecurityError messages
  const message = notice.errors[0].message
  if (message.indexOf('Blocked a frame with origin') === -1) {
    return notice
  } else {
    return null
  }
}

const _cssRulesFilter = function (notice) {
  // Filters out "Failed to read the 'cssRules' property from 'CSSStyleSheet':
  // Cannot access rules" errors
  const message = notice.errors[0].message
  if (message.indexOf('Failed to read the \'cssRules\' property') === -1) {
    return notice
  } else {
    return null
  }
}

const _toStringFilter = function (notice) {
  // Filters out typo in SamsungInternet until update is released
  // https://github.com/SamsungInternet/support/issues/56
  const message = notice.errors[0].message
  if (message.indexOf('document.getElementsByClassName.ToString is not a function') === -1) {
    return notice
  } else {
    return null
  }
}

const _trapIframeMethodsFilter = function (notice) {
  // Filters out errors caused by iframeMethodFound function
  const backtrace = notice.errors[0].backtrace
  let iframeMethodFound = false

  backtrace.forEach(function (line) {
    if (line.function === 'trapIFrameMethods') {
      iframeMethodFound = true
    }
  })

  if (iframeMethodFound) {
    return null
  } else {
    return notice
  }
}

const _isNotDefinedFilter = function (notice) {
  // Filters out #5335
  const message = notice.errors[0].message
  if ((message.indexOf('vid_mate_check is not defined') === -1) &&
     (message.indexOf('removeNightMode is not defined') === -1)) {
    return notice
  } else {
    return null
  }
}

const _facebookBotFilter = function (notice) {
  // Filters out #5307 and #5278
  const message = notice.errors[0].message
  if (message.indexOf('Failed to execute \'postMessage\' on \'Window\'') === -1) {
    return notice
  } else {
    return null
  }
}

const _lastPassFilter = function (notice) {
  // Filters out #6545
  const message = notice.errors[0].message
  if (message.indexOf('l.LegacyGlobal.should_do_lastpass_here is not a function.') === -1) {
    return notice
  } else {
    return null
  }
}

const _lazysizesInvocationFilter = function (notice) {
  // Filters out TypeError: Illegal invocation from lazysizes library #6368
  let createEventError = false

  notice.errors[0].backtrace.forEach(function (line) {
    if (line.function.indexOf('HTMLDocument.document.createEvent') !== -1) {
      createEventError = true
    }
  })

  const message = notice.errors[0].message
  if (createEventError && message.indexOf('Illegal invocation') !== -1) {
    return null
  } else {
    return notice
  }
}

const _autofillCallbackFilter = function (notice) {
  // Filters out ReferenceError: Can't find variable: _AutofillCallbackHandler error

  const message = notice.errors[0].message
  if (message.indexOf('Can\'t find variable: _AutofillCallbackHandler') !== -1) {
    return null
  } else {
    return notice
  }
}

const _microsoftLinkFilter = function (notice) {
  // #9884
  // Filters out Object Not Found Matching Id error

  const message = notice.errors[0].message
  if (message.indexOf('Object Not Found Matching Id') !== -1) {
    return null
  } else {
    return notice
  }
}

const _resizeObserverFilter = function (notice) {
  // #11325
  // Filters out ResizeObserver error

  const message = notice.errors[0].message
  if (message.indexOf('Can\'t find variable: ResizeObserver') !== -1) {
    return null
  } else {
    return notice
  }
}

const _webkitMaskedUrlHiddenFilter = function (notice) {
  // #13814
  // Filters out webkit-masked-url errors caused by safari webkit
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('webkit-masked-url://hidden') === -1) {
    return notice
  } else {
    return null
  }
}

const _chromeExtensionFilter = function (notice) {
  // #13849
  // Filters out Chrome Extension errors
  const fileName = notice.errors[0].backtrace[0].file
  if (fileName.indexOf('chrome-extension://') === -1) {
    return notice
  } else {
    return null
  }
}

const _symBrowserFilter = function (notice) {
  // #13913
  // Filters out SymBrowser_ error

  const message = notice.errors[0].message
  if (message.indexOf('Can\'t find variable: SymBrowser_') !== -1) {
    return null
  } else {
    return notice
  }
}

const airbrakeJsFilters = [
  _developmentFilter,
  _kasperskyFilter, _civiccomputingFilter, _cookiesDisabledFilter,
  _facebookSignalsFilter, _smoochFilter, _twitterFilter, _botFilter, _baiduFilter,
  _googleApiFilter, _parentNodeFilter, _antiPhishingFilter, _securityFilter,
  _cssRulesFilter, _toStringFilter, _trapIframeMethodsFilter, _isNotDefinedFilter,
  _facebookBotFilter, _lastPassFilter, _lazysizesInvocationFilter, _autofillCallbackFilter,
  _microsoftLinkFilter, _resizeObserverFilter, _webkitMaskedUrlHiddenFilter,
  _chromeExtensionFilter, _symBrowserFilter
]

export default airbrakeJsFilters
