import { Controller } from '@hotwired/stimulus'
import Masonry from 'masonry-layout/masonry'

export default class extends Controller {
  static get values () {
    return {
      columnWidth: Number,
      itemSelector: String,
      gutter: { type: Number, default: 26 },
      fitWidth: { type: Boolean, default: false },
      horizontalOrder: { type: Boolean, default: false }
    }
  }

  masonryInstance = null

  connect () {
    if (!this.masonryInstance) {
      this.createMasonryInstance()
    }
  }

  createMasonryInstance () {
    this.masonryInstance = new Masonry(this.element, {
      columnWidth: this.columnWidthValue,
      itemSelector: this.itemSelectorValue,
      gutter: this.gutterValue,
      fitWidth: this.fitWidthValue,
      horizontalOrder: this.horizontalOrderValue
    })
  }
};
