import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['scoreButton']
  }

  hoverScore (event) {
    this.highlightScore(event.currentTarget)
  }

  highlightScore (target) {
    target.querySelector('.rating-submit').classList.add('active')

    if (target.previousElementSibling) {
      this.highlightScore(target.previousElementSibling)
    }
  }

  removeHoverColours (event) {
    this.scoreButtonTargets.forEach((score) => {
      score.classList.remove('active')
    })
  }
}
