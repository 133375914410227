import { Controller } from '@hotwired/stimulus'
import consumer from 'channels/consumer'

export default class extends Controller {
  static get targets () {
    return ['button', 'spinner']
  }

  connect () {
    const paymentIdentifier = this.element.dataset.paymentIdentifier
    const provider = this.element.dataset.provider

    if (paymentIdentifier && provider) {
      this.channel = consumer.subscriptions.create(
        {
          channel: 'PaymentRequestDataReceivedChannel',
          payment_identifier: paymentIdentifier,
          provider
        },
        {
          received: this.process
        }
      )
    }
  }

  process () {
    window.location.reload()
  }

  beginPayment () {
    this.buttonTarget.style.display = 'none'
    this.spinnerTarget.classList.remove('hidden')

    const url = this.buttonTarget.dataset.url
    setTimeout(() => {
      window.location.href = url
    }, 4000)
  }
}
