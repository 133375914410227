import { Controller } from '@hotwired/stimulus'
import { Tab } from 'bootstrap'

export default class extends Controller {
  static get targets () {
    return ['link']
  }

  connect () {
    this.linkTargets.forEach((el) => new Tab(el))
  }

  updateTab (event) {
    event.preventDefault()
    const element = this.element.querySelector(`#${event.currentTarget.dataset.selector}`)
    Tab.getInstance(element).show()
  }
}
