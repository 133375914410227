const initializeTrackClick = () => {
  if (window.analytics !== undefined) {
    if (window.userId !== 'undefined' && window.userId !== undefined) {
      window.analytics.identify(window.userId, window.userDetails)
    } else {
      window.analytics.identify(window.anonId)
    }

    const trackClickInstance = new TrackClick()
    trackClickInstance.init()
  }
}

const TrackClick = class {
  init () {
    for (const eventName of this.uniqueEventNames()) {
      this.trackLink(eventName)
    }
  }

  uniqueEventNames () {
    return Array.from(new Set(this.gatherEventNames()))
  }

  gatherEventNames () {
    const eventNames = []
    document.querySelectorAll('[data-mp-event-name]').forEach(function (element) {
      eventNames.push(element.dataset.mpEventName)
    })
    return eventNames
  }

  trackLink (eventName) {
    const elements = document.querySelectorAll(`[data-mp-event-name='${eventName}']`)

    elements.forEach(element => {
      element.addEventListener('click', (event) => {
        const extraProperties = { ...element.dataset }
        delete extraProperties.mpEventName
        window.analytics.track(eventName, extraProperties)
      })
    })
  }
}

export default initializeTrackClick
