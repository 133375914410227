import Chart from 'charts/config'

const initializeDoughnut = (canvasElement, chartData) => {
  const doughnutChart = {
    afterDraw: function (chart, option) {
      const width = chart.width

      chart.ctx.fillStyle = '#4a4a4a'
      chart.ctx.textBaseline = 'middle'
      chart.ctx.textAlign = 'center'

      const titleText = chart.options.title.text
      const startingHeight = (chart.height / 2.2)
      const startingWidth = width / 2

      let mediumFontSize
      if (width < 300) {
        mediumFontSize = 1
      } else {
        mediumFontSize = 1.2
      }

      chart.ctx.font = `lighter ${mediumFontSize}em "canada-type-gibson"`

      chart.ctx.fillText(titleText[0], startingWidth, startingHeight)
      chart.ctx.fillText(titleText[1], startingWidth, startingHeight + 20)

      let largerFontSize
      if (width < 300) {
        largerFontSize = 1.8
      } else {
        largerFontSize = 2.3
      }

      chart.ctx.font = `lighter ${largerFontSize}em "canada-type-gibson"`
      chart.ctx.fillText(titleText[2], startingWidth, startingHeight + 60)

      chart.canvas.classList.add('doughnut-after-draw-done')
    },
    beforeInit: function (chart, args, options) {
      const ul = document.createElement('ul')
      ul.classList.add('no-list-style', 'no-padding-left')

      chart.data.legends.forEach((legend, i) => {
        ul.innerHTML += `
          <li><span id='legend-block-${i}'></span>
            <small>${legend.label}</small>
          </li>
        `
      })
      chart.data.legends.forEach((legend, i) => {
        const span = ul.querySelector(`span#legend-block-${i}`)
        span.style.backgroundColor = legend.backgroundColour
      })

      return document.getElementsByClassName('chart-legend')[0].appendChild(ul)
    }
  }

  const Doughnut = (canvasElement, chartData) => {
    return new Chart(canvasElement, {
      type: 'doughnut',
      data: {
        datasets: chartData.datasets,
        legends: chartData.legends
      },
      options: {
        cutout: '65%',
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text: chartData.titleText
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            boxPadding: 6,
            callbacks: {
              title: () => '',
              label: function (context) {
                return context.dataset.hoverLabel[context.dataIndex]
              }
            },
            position: 'cursor'
          }
        }
      },
      plugins: [doughnutChart]
    })
  }

  return new Doughnut(canvasElement, chartData)
}

export default initializeDoughnut
