import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['dropzone']
  }

  dragstart () {
    if (!this.dragging) {
      this.dropzoneTargets.forEach((dropzone) => dropzone.classList.add('in'))
      this.dragging = true
    }
  }

  dragenter (event) {
    this.dropzoneTargets.forEach((dropzone) => {
      if (event.currentTarget === dropzone) {
        event.currentTarget.classList.add('hover')
      } else {
        dropzone.classList.remove('hover')
      }
    })
  }

  dragend () {
    this.dragging = false
    this.dropzoneTargets.forEach((dropzone) => {
      dropzone.classList.remove('hover')
      dropzone.classList.remove('in')
    })
  }
}
