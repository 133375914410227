import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values () {
    return {
      href: { type: String, default: '/sessions/timeout' },
      delay: { type: Number, default: 590000 }
    }
  };

  beginCountdown () {
    const timeoutUser = () => {
      window.location.href = this.hrefValue
    }

    setTimeout(timeoutUser, this.delayValue)
  }
}
