import Mailcheck from 'mailcheck'

Mailcheck.defaultTopLevelDomains.push('uk')
Mailcheck.defaultSecondLevelDomains.push('libero')

if (process.env.RAILS_ENV === 'test') {
  Mailcheck.defaultTopLevelDomains.push('test')
}

export default Mailcheck
