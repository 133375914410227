import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['expand', 'close']
  }

  expand () {
    this.expandTargets.forEach((target) => target.classList.remove('d-flex'))
    this.expandTargets.forEach((target) => target.classList.add('d-none'))

    this.closeTargets.forEach((target) => target.classList.remove('d-none'))
    this.closeTargets.forEach((target) => target.classList.add('d-flex'))
  }

  close () {
    this.expandTargets.forEach((target) => target.classList.add('d-flex'))
    this.expandTargets.forEach((target) => target.classList.remove('d-none'))

    this.closeTargets.forEach((target) => target.classList.add('d-none'))
    this.closeTargets.forEach((target) => target.classList.remove('d-flex'))
  }
}
