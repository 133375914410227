import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['income', 'assets', 'commitments', 'worth', 'percentage', 'maxInvestment']
  }

  connect () {
    if (this.hasMaxInvestmentTarget) {
      this.maxInvestmentTarget.innerHTML = this._nearestLowerThousand(0)
    }
  }

  get formatter () {
    return new Intl.NumberFormat(document.documentElement.lang, {
      currency: 'EUR'
    })
  }

  get incomeValue () {
    return this._formatValue(this.incomeTarget.autoNumeric.rawValue || '0')
  }

  get assetsValue () {
    return this._formatValue(this.assetsTarget.autoNumeric.rawValue || '0')
  }

  get commitmentsValue () {
    return this._formatValue(this.commitmentsTarget.autoNumeric.rawValue || '0')
  }

  calculate () {
    const result = this.incomeValue + this.assetsValue - this.commitmentsValue

    this.worthTarget.innerHTML = this.formatter.format(result)

    const noCentsFormatter = new Intl.NumberFormat(document.documentElement.lang, {
      currency: 'EUR',
      maximumFractionDigits: 0
    })

    const fivePercent = result * 0.05
    this.percentageTarget.innerHTML = noCentsFormatter.format(fivePercent)

    if (this.hasMaxInvestmentTarget) {
      this.maxInvestmentTarget.innerHTML = this._nearestLowerThousand(fivePercent)
    }
  }

  _formatValue (textInput) {
    return parseFloat(this._normalizeValue(textInput))
  }

  _normalizeValue (textInput) {
    return textInput.replaceAll(',', '')
  }

  _nearestLowerThousand (amount) {
    const roundedDown = amount >= 2000 ? Math.floor(amount / 1000) * 1000 : 1000

    return this.formatter.format(roundedDown)
  }
}
