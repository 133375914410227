/* global DOMParser */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values () {
    return { data: String }
  }

  show () {
    if (!this.element.querySelector('.pre-hover-container')) {
      const child = document.createElement('pre')
      child.classList.add('pre-hover-container')
      this.formatData(child)
      this.element.appendChild(child)
    }
  }

  formatData (child) {
    const dataContent = this.dataValue.trim()

    try {
      const jsonData = JSON.parse(dataContent)
      const jsonString = JSON.stringify(jsonData, null, 2)
      child.innerHTML = jsonString
    } catch (error) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(dataContent, 'text/html')

      if (doc.body.children.length > 0) {
        child.innerHTML = dataContent
      } else {
        child.innerText = dataContent
      }
    }
  }

  hide () {
    const child = this.element.querySelector('.pre-hover-container')
    if (child) {
      child.remove()
    }
  }
}
