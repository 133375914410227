import { Controller } from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'
import { i18n } from 'i18n'

export default class extends Controller {
  static get targets () {
    return ['input']
  }

  static get values () {
    return {
      cents: Boolean,
      overrideMinMaxLimits: { type: String, default: 'ignore' },
      euroLocale: Boolean,
      requiredMessage: String,
      underflowMessage: String,
      overflowMessage: String,
      stepMessage: String
    }
  }

  get requiredMessage () {
    return this.requiredMessageValue || i18n.t('client-errors.messages.required')
  }

  get underflowMessage () {
    return this.underflowMessageValue || i18n.t('client-errors.messages.underflow')
      .replace(/\{\d*\}/, this.inputTarget.min)
  }

  get overflowMessage () {
    return this.overflowMessageValue || i18n.t('client-errors.messages.overflow')
      .replace(/\{\d*\}/, this.inputTarget.max)
  }

  get stepMessage () {
    return this.stepMessageValue || i18n.t('client-errors.messages.step')
      .replace(/\{\d*\}/, this.stepValue)
  }

  get minValue () {
    return parseFloat(this.inputTarget.min) || 0
  }

  get maxValue () {
    return parseFloat(this.inputTarget.max)
  }

  get stepValue () {
    return parseInt(this.inputTarget.step)
  }

  connect () {
    if (this.hasInputTarget) {
      const decimalPlaces = this.centsValue ? 2 : 0

      const normal = { decimalPlaces, unformatOnSubmit: true }

      let euro = { }
      if (this.euroLocaleValue) {
        euro = { decimalCharacter: ',', decimalCharacterAlternative: '.', digitGroupSeparator: '.' }
      }
      const settings = { ...normal, ...euro }

      settings.minimumValue = this.minValue

      settings.overrideMinMaxLimits = this.overrideMinMaxLimitsValue

      if (this.maxValue) {
        settings.maximumValue = this.maxValue
      }

      this.autoNumeric = new AutoNumeric(this.inputTarget, settings)
      this.inputTarget.autoNumeric = this.autoNumeric

      if (decimalPlaces > 0) {
        this.inputTarget.inputMode = null
      }

      this.validate()
    }
  }

  isInputEmpty () {
    return this.inputTarget.value.trim().length === 0
  }

  isInputRequired () {
    return this.inputTarget.required
  }

  validate () {
    let message = ''

    if (this.isInputEmpty() && this.isInputRequired()) {
      message = this.requiredMessage
    } else if (this.minValue > this.autoNumeric.getNumber()) {
      message = this.underflowMessage
    } else if (this.autoNumeric.getNumber() > this.maxValue) {
      message = this.overflowMessage
    } else if (this.stepValue > 0 && this.autoNumeric.getNumber() % this.stepValue !== 0) {
      message = this.stepMessage
    }

    this.inputTarget.setCustomValidity(message)
  }
}
