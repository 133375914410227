// https://github.com/jorgemanrubia/rails-form-validations-example/
//   blob/master/app/javascript/controllers/form_controller.js

import { Controller } from '@hotwired/stimulus'
import { validationMessage } from 'components/validation'

export default class extends Controller {
  static get values () {
    return {
      errorClass: { type: String, default: 'authentication-error' }
    }
  }

  connect () {
    this.element.setAttribute('novalidate', true)
  }

  onBlur (event) {
    this.validateField(event.target)
  }

  onValidate () {
    if (!this.validateForm()) {
      this.firstInvalidField.focus()
    }
  }

  onSubmit (event) {
    if (!this.validateForm()) {
      event.preventDefault()
      this.firstInvalidField.focus()
    }
  }

  validateForm () {
    let isValid = true
    // Not using `find` because we want to validate all the fields
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) {
        isValid = false
      }
    })
    return isValid
  }

  validateField (field) {
    if (!this.shouldValidateField(field)) {
      return true
    }
    const isValid = field.checkValidity()
    field.classList.toggle('invalid', !isValid)
    this.refreshErrorForInvalidField(field, isValid)
    return isValid
  }

  shouldValidateField (field) {
    return !field.disabled && !['reset', 'submit', 'button'].includes(field.type)
  }

  refreshErrorForInvalidField (field, isValid) {
    this.removeExistingErrorMessage(field)
    if (!isValid) {
      this.showErrorForInvalidField(field)
    }
  }

  removeExistingErrorMessage (field) {
    const existingErrorMessageElement = this.element.querySelector(`#${field.id}-error`)

    if (existingErrorMessageElement) {
      existingErrorMessageElement.remove()
    }

    const existingGroupContainer = this.element.querySelector(`#${field.dataset.group}`)

    if (existingGroupContainer) {
      existingGroupContainer.innerHTML = ''
    }
  }

  showErrorForInvalidField (field) {
    const groupContainer = this.element.querySelector(`#${field.dataset.group}`)
    if (groupContainer) {
      groupContainer.innerHTML = validationMessage(field)
    } else {
      const inputContainer = field.closest('.input-container') || field
      inputContainer.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
    }
  }

  buildFieldErrorHtml (field) {
    const message = validationMessage(field)
    return `<label id="${field.id}-error" for="${field.id}" class="${this.errorClassValue}">${message}</label>`
  }

  get formFields () {
    return Array.from(this.element.querySelectorAll('input, select, textarea'))
  }

  get firstInvalidField () {
    return this.formFields.find((field) => !field.checkValidity())
  }
}
