import Chart from 'charts/config'

const initializeSummaryChart = (canvasElement, summaryData) => {
  const summaryTitle = {
    afterDraw: function (chart, option) {
      chart.canvas.classList.add('summary-after-draw-done')
    },

    beforeDraw: function (chart, option) {
      const width = chart.width

      chart.ctx.fillStyle = '#4a4a4a'
      chart.ctx.textBaseline = 'middle'
      chart.ctx.textAlign = 'center'

      const titleText = chart.options.title.text
      const largerCount = chart.options.title.largerCount
      const startingWidth = width / 2

      let startingHeight
      let smallerFontSize
      if (width < 300) {
        startingHeight = chart.height / 2.1
        smallerFontSize = 0.9
      } else {
        startingHeight = chart.height / 2.4
        smallerFontSize = 1.1
      }

      chart.ctx.font = `lighter ${smallerFontSize}em "canada-type-gibson"`
      chart.ctx.fillText(titleText[0], startingWidth, startingHeight)

      let largerFontSize
      if (width < 300) {
        largerFontSize = 2
      } else {
        largerFontSize = 2.3
      }

      for (let i = 1; i < titleText.length; i++) {
        if (i >= (titleText.length - largerCount)) {
          chart.ctx.font = `lighter ${largerFontSize}em "canada-type-gibson"`
          chart.ctx.fillText(titleText[i], startingWidth, startingHeight + 5 + (i * 30))
        } else {
          chart.ctx.fillText(titleText[i], startingWidth, startingHeight + 5 + (i * 15))
        }
      }
    }
  }

  const SummaryChart = (canvasElement, summaryData) => {
    return new Chart(canvasElement, {
      type: 'doughnut',
      data: {
        datasets: [summaryData.dataset],
        labels: summaryData.labels
      },
      options: {
        cutout: '75%',
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: summaryData.labels !== undefined,
            boxPadding: 6,
            callbacks: {
              title: () => '',
              label: function (context) {
                // Returns raise title, with amount and currency

                const index = context.datasetIndex
                return `${context.label}: ${context.dataset.currency[index]}${context.formattedValue}`
              }
            }
          }
        },
        maintainAspectRatio: false,
        title: {
          text: summaryData.titleText,
          largerCount: summaryData.lastLargerTitles
        }
      },
      plugins: [summaryTitle]
    })
  }

  return new SummaryChart(canvasElement, summaryData)
}

export default initializeSummaryChart
