import { i18n } from 'i18n'

export const validationMessage = (field) => {
  return fetchMessage(field) || i18n.t('client-errors.messages.default')
}

const fetchMessage = (field) => {
  const customMessages = JSON.parse(field.dataset.messages || '{}')
  const validity = field.validity

  if (validity.valueMissing) {
    return customMessages.required || i18n.t('client-errors.messages.required')
  }

  if (validity.typeMismatch) {
    return customMessages.invalid
  }

  if (validity.tooShort) {
    return (customMessages.tooShort || i18n.t('client-errors.messages.too-short'))
      .replace(/\{\d*\}/, field.minLength)
  }

  if (validity.rangeUnderflow) {
    return customMessages.underflow || i18n.t('client-errors.messages.underflow')
      .replace(/\{\d*\}/, field.min)
  }

  if (validity.rangeOverflow) {
    return customMessages.overflow || i18n.t('client-errors.messages.overflow')
      .replace(/\{\d*\}/, field.max)
  }

  if (validity.patternMismatch) {
    return customMessages.pattern
  }

  if (validity.customError) {
    return field.validationMessage
  }
}
