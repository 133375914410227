import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values () {
    return {
      url: String
    }
  }

  connect () {
    setTimeout(() => {
      window.location.href = this.urlValue
    }, 4000)
  }
}
