import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['frame', 'input']
  }

  static get values () {
    return {
      baseUrl: String,
      placeholder: String
    }
  }

  get templates () {
    return Array.from(document.querySelectorAll('.js-template'))
  }

  loadUrl () {
    if (this.inputTarget.value) {
      this.frameTarget.src = `${this.baseUrlValue}${this.inputTarget.value}`
      this.frameTarget.reload()
    } else {
      this.frameTarget.innerHTML = ''
    }
  }

  loadTemplate () {
    const template = this.templates.find((target) => {
      return target.id === this.inputTarget.selectedOptions[0].dataset.target
    })

    this.frameTarget.innerHTML = ''

    if (template) {
      const clone = template.content.cloneNode(true)
      this.frameTarget.appendChild(clone)
    }
  }
}
