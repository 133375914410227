import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['list', 'notification']
  }

  checkboxChange () {
    this.notificationTarget.innerHTML = null
  }

  selectAll () {
    this._toggleAll(true)
  }

  unselectAll () {
    this._toggleAll(false)
  }

  _toggleAll (on) {
    this.listTargets.forEach((list) => {
      list.checked = on
    })
  }
}
