import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static get targets () {
    return ['countryName', 'countrySelected']
  }

  connect () {
    this.initialAttributes()
  }

  initialAttributes () {
    this.initiallySelectedValue = this.countrySelectedTarget.value
    this.initiallySelectedTerritory = this.countrySelectedTarget.selectedOptions[0].dataset.territory
  }

  yes () {
    this.initialAttributes()
    this.closeModal()
  }

  no () {
    if (this.countrySelectedTarget.slimSelectInstance) {
      this.countrySelectedTarget.slimSelectInstance.setSelected(this.initiallySelectedValue)
    } else {
      this.countrySelectedTarget.value = this.initiallySelectedValue
    }
    this.closeModal()
  }

  country () {
    this.newTerritory = this.countrySelectedTarget.selectedOptions[0].dataset.territory
    if (this.showTerritoryChangeModal()) {
      const newCountryName = this.countrySelectedTarget.selectedOptions[0].text
      this.showModal(newCountryName)
    }
  }

  showModal (countryName) {
    this.countryNameTarget.innerHTML = countryName
    this.modal = new Modal(document.getElementById('territory-selection-confirmation'))
    this.modal.show()
  }

  closeModal () {
    this.modal.hide()
  }

  showTerritoryChangeModal () {
    return this.territoryChanged && (this.changedFromEu || this.changedToEu)
  }

  get territoryChanged () {
    return this.initiallySelectedTerritory !== this.newTerritory
  }

  get changedFromEu () {
    return this.isEu && this.newTerritory !== 'eu'
  }

  get changedToEu () {
    return this.isNotEu && this.newTerritory === 'eu'
  }

  get isEu () {
    return this.initiallySelectedTerritory === 'eu'
  }

  get isNotEu () {
    return !this.isEu
  }
};
