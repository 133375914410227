import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values () {
    return {
      regex: String,
      message: String
    }
  }

  matches (event) {
    const regex = new RegExp(this.regexValue)
    this._testRegex(event.currentTarget, regex)
  }

  caseInsensitiveMatch (event) {
    const regex = new RegExp(this.regexValue, 'i')
    this._testRegex(event.currentTarget, regex)
  }

  _testRegex (target, regex) {
    if (regex.test(target.value)) {
      target.setCustomValidity('')
    } else {
      target.setCustomValidity(this.messageValue)
    }
  }
}
