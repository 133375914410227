// Originally from https://github.com/epiksel/countdown

const initializeCountdown = (container, options, callback) => {
  const settings = Object.assign({
    date: null,
    offset: null,
    day: 'Day',
    days: 'Days',
    hour: 'Hour',
    hours: 'Hours',
    minute: 'Minute',
    minutes: 'Minutes',
    second: 'Second',
    seconds: 'Seconds',
    hideOnComplete: false
  }, options)

  // Throw error if date is not set
  if (!settings.date) {
    throw new Error('Date is not defined.')
  }

  // Throw error if date is set incorrectly
  if (!Date.parse(settings.date)) {
    throw new Error('Incorrect date format, it should look like this, 12/24/2012 12:00:00.')
  }

  /**
   * Change client's local date to match offset timezone
   * @return {Object} Fixed Date object.
   */
  function getCurrentDate () {
    // get client's current date
    const date = new Date()

    // turn date to utc
    const utc = date.getTime() + (date.getTimezoneOffset() * 60000)

    // set new Date object
    const newDate = new Date(utc + (3600000 * settings.offset))

    return newDate
  }

  function updateDisplay (days, hours, minutes, seconds) {
    // based on the date change the reference wording
    const textDays = (days === 1) ? settings.day : settings.days
    const textHours = (hours === 1) ? settings.hour : settings.hours
    const textMinutes = (minutes === 1) ? settings.minute : settings.minutes
    const textSeconds = (seconds === 1) ? settings.second : settings.seconds

    // fix dates so that it will show two digits
    days = (String(days).length >= 2) ? days : '0' + days
    hours = (String(hours).length >= 2) ? hours : '0' + hours
    minutes = (String(minutes).length >= 2) ? minutes : '0' + minutes
    seconds = (String(seconds).length >= 2) ? seconds : '0' + seconds

    // set to DOM
    updateTarget('.days', days)
    container.querySelector('.hours').textContent = hours
    container.querySelector('.minutes').textContent = minutes
    container.querySelector('.seconds').textContent = seconds

    updateTarget('.days-text', textDays)
    container.querySelector('.hours-text').textContent = textHours
    container.querySelector('.minutes-text').textContent = textMinutes
    container.querySelector('.seconds-text').textContent = textSeconds
  }

  function updateTarget (selector, value) {
    const target = container.querySelector(selector)

    if (target) {
      target.textContent = value
    }
  }

  /**
   * Main countdown function that calculates everything
   */
  function updateCountdown () {
    const targetDate = new Date(settings.date) // set target date
    const currentDate = getCurrentDate() // get fixed current date

    // difference of dates
    const difference = targetDate - currentDate

    // if difference is negative then it's past the target date
    if (difference < 0) {
      // stop timer
      clearInterval(interval)

      if (settings.hideOnComplete) {
        container.style.display = 'none'
      }

      if (callback && typeof callback === 'function') {
        callback(container)
      }

      return
    }

    // basic math variables
    const _second = 1000
    const _minute = _second * 60
    const _hour = _minute * 60
    const _day = _hour * 24

    // calculate dates
    const days = Math.floor(difference / _day)
    const hours = Math.floor((difference % _day) / _hour)
    const minutes = Math.floor((difference % _hour) / _minute)
    const seconds = Math.floor((difference % _minute) / _second)

    updateDisplay(days, hours, minutes, seconds)
  }

  // start
  const interval = setInterval(updateCountdown, 1000)
}

export default initializeCountdown
