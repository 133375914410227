import { Controller } from '@hotwired/stimulus'
import ErrorModal from 'components/error_modal'
import { i18n } from 'i18n'
import { getStripe } from 'components/stripe'

export default class extends Controller {
  static get targets () {
    return ['error', 'identifier']
  }

  static get values () {
    return {
      backLink: String,
      clientSecret: String
    }
  }

  async connect () {
    this.Stripe = await getStripe()
    await this.confirmPaymentWithIntent()
  }

  async confirmPaymentWithIntent () {
    this.Stripe.confirmCardPayment(this.clientSecretValue)
      .then((result) => {
        if (result.error) {
          this.showErrorModal({
            errorTitle: i18n.t('card-errors.card_declined'),
            errorDetail: result.error.message,
            backLink: this.backLinkValue
          })
        } else {
          this.identifierTarget.value = result.paymentIntent.id
          this.element.requestSubmit()
        }
      })
  }

  showErrorModal (errorMessage) {
    const errorModal = new ErrorModal()

    errorModal.error({
      title: errorMessage.errorTitle,
      detail: errorMessage.errorDetail,
      backLink: errorMessage.backLink
    })
  }
}
