import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static get values () {
    return { src: String }
  }

  static get targets () {
    return ['select']
  }

  updateOptions () {
    this.selectTarget.innerHTML = '<option value=\'loading\'>Loading...</option>'
    this.fetchOptions()
  }

  async fetchOptions (callback, errorCallback) {
    const result = await get(this.srcValue)

    if (result.ok) {
      const json = await result.response.json()
      this.populateOptions(json.data)
    } else {
      this.errorCallback()
    }
  }

  populateOptions (results) {
    this.selectTarget.innerHTML = results.map((item, index) => {
      return `<option value='${item.value}'>${item.text}</option>`
    }).join('')
  }

  errorCallback () {
    this.selectTarget.innerHTML = null
  }
}
