import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'uniqueAnswer', 'nonUniqueAnswer', 'feedback', 'feedbackCheckbox']
  static values = { message: String }

  validateAnyChecked () {
    const message = this.isAnyChecked() ? '' : this.messageValue
    this.assignMessage(message)
  }

  optionClickedWithFeedbackTarget () {
    if (this.hasFeedbackCheckboxTarget) {
      this.feedbackTarget.disabled = !this.feedbackCheckboxTarget.checked
    }
  }

  uniqueOptionClicked () {
    if (this.hasUniqueAnswerTarget && this.uniqueAnswerTarget.checked) {
      this.nonUniqueAnswerTargets.forEach(element => {
        element.checked = false
      })
    }
    this.optionClickedWithFeedbackTarget()
  }

  nonUniqueOptionClicked () {
    if (this.hasUniqueAnswerTarget && this.uniqueAnswerTarget.checked) {
      this.uniqueAnswerTarget.checked = false
    }
    this.optionClickedWithFeedbackTarget()
  }

  isAnyChecked () {
    return this.inputTargets.some(element => element.checked)
  }

  assignMessage (message) {
    this.inputTargets.forEach(element => element.setCustomValidity(message))
  }
}
