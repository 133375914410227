import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['field', 'eye']
  }

  togglePasswordVisibility () {
    if (this.fieldTarget.type === 'password') {
      this.reveal()
    } else {
      this.disguise()
    }
  }

  reveal () {
    this.fieldTarget.type = 'text'

    this.eyeTarget.classList.remove('fa-eye')
    this.eyeTarget.classList.add('fa-eye-slash')
  }

  disguise () {
    this.fieldTarget.type = 'password'

    this.eyeTarget.classList.remove('fa-eye-slash')
    this.eyeTarget.classList.add('fa-eye')
  }
}
