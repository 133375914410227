import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['question']
  }

  openAll () {
    this.questionTargets.forEach((target) => {
      target.open = true
    })
  }

  closeAll () {
    this.questionTargets.forEach((target) => {
      target.open = false
    })
  }
}
