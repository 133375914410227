function elementScrolled (elem) {
  const docViewTop = window.pageYOffset || document.documentElement.scrollTop
  const elemTop = elem.offsetTop
  return elemTop >= docViewTop
}

const mainFooterScrolled = () => {
  const main = document.querySelector('main')
  const article = document.querySelector('article')

  const selectedNode = main || article || document.body

  if (elementScrolled(selectedNode)) {
    document.querySelectorAll('.sticky-risk').forEach(function (element) {
      element.classList.remove('in-main')
    })
  } else {
    document.querySelectorAll('.sticky-risk').forEach(function (element) {
      element.classList.add('in-main')
    })
  }
}

export default mainFooterScrolled
