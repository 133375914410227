/* global grecaptcha */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values () {
    return {
      siteKey: String
    }
  }

  initialize () {
    grecaptcha.render('recaptchaV2', { sitekey: this.siteKeyValue })
  }
}
