import Chart from 'charts/config'

const initializeBar = (canvasElement, chartData, scaleMax) => {
  const barChart = {
    afterDraw: function (chart, option) {
      chart.canvas.classList.add('bar-after-draw-done')
    },
    beforeInit: function (chart, args, options) {
      const ul = document.createElement('ul')
      ul.classList.add('no-list-style', 'no-padding-left')

      chart.data.labels.forEach((label, i) => {
        ul.innerHTML += `
          <li><span></span>
            <small>${label}</small>
          </li>
        `
        const span = ul.lastElementChild.querySelector('span')
        span.style.backgroundColor = chart.data.datasets[0].backgroundColor[i]
      })

      return document.getElementsByClassName('chart-legend')[0].appendChild(ul)
    }
  }

  const Bar = (canvasElement, chartData, scaleMax) => {
    return new Chart(canvasElement, {
      type: 'bar',
      data: {
        labels: chartData[0].title,
        datasets: chartData
      },
      options: {
        aspectRatio: 1.5,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            border: {
              display: false
            },
            grid: {
              display: false
            },
            stacked: true,
            max: scaleMax,
            ticks: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            },
            stacked: true,
            ticks: {
              display: true,
              minRotation: 20,
              font: function (context) {
                return {
                  size: 16,
                  weight: 600
                }
              }
            },
            afterFit: function (scaleInstance) {
              scaleInstance.width = 120
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            boxPadding: 6,
            callbacks: {
              title: () => '',
              label: function (tooltip, data) {
                return tooltip.dataset.hoverLabel
              }
            },
            mode: 'dataset',
            position: 'cursor'
          }
        }
      },
      plugins: [barChart]
    })
  }

  return new Bar(canvasElement, chartData, scaleMax)
}

export default initializeBar
