import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['input']
  }

  static get values () {
    return {
      message: String
    }
  }

  get inputValues () {
    return this.inputTargets.map((element) => {
      return element.value
    })
  }

  get uniqueInputValues () {
    return this.inputValues.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
  }

  validateDistinct (event) {
    if (this.isDistinct()) {
      event.currentTarget.setCustomValidity('')
    } else {
      event.currentTarget.setCustomValidity(this.messageValue)
    }
  }

  isDistinct () {
    return JSON.stringify(this.inputValues) === JSON.stringify(this.uniqueInputValues)
  }
}
