import { Controller } from '@hotwired/stimulus'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  initializeClipboards () {
    this.clipboardInstances = new ClipboardJS('.clipboard-icon')
  }

  connect () {
    this.initializeClipboards()
  }

  disconnect () {
    if (this.clipboardInstances) {
      this.clipboardInstances.destroy()
    }
  }
}
