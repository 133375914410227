import { Controller } from '@hotwired/stimulus'
import consumer from 'channels/consumer'
import { i18n } from 'i18n'

export default class extends Controller {
  static get targets () {
    return ['fabrickprocessing']
  }

  connect () {
    const reference = this.element.dataset.reference
    if (reference) {
      this.tooLong()

      this.channel = consumer.subscriptions.create(
        {
          channel: 'PaymentStepDataReceivedChannel',
          reference
        },
        {
          received: this.process
        }
      )
    }
  }

  tooLong () {
    if (this.hasFabrickprocessingTarget) {
      setTimeout(() => {
        this.fabrickprocessingTarget.textContent = i18n.t('investments.completed.fabrick-processing-too-long')
      }, 60000)
    }
  }

  process () {
    window.location.reload()
  }
}
