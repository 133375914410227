import { Controller } from '@hotwired/stimulus'
import Mailcheck from 'mailcheck_config'
import { i18n } from 'i18n'

export default class extends Controller {
  static get values () {
    return { link: String }
  }

  static get targets () {
    return ['hint', 'input', 'warning']
  }

  initMailCheck () {
    Mailcheck.run({
      email: this.inputTarget.value,
      suggested: (suggestion) => {
        this.hintSuggested(suggestion.full)
      },

      empty: (suggestion) => this.clearHint
    })
  }

  hintSuggested (hint) {
    this.hintTarget.innerHTML = this.hintElement(hint)
  }

  hintElement (hint) {
    const element = `
      <button class='no-button' data-action='click->hints#suggestedEmailClicked' type='button'>
        ${hint}
      </button>
    `
    return i18n.t('forms.sign-up.email-hint', { hint: element })
  }

  clearHint () {
    this.hintTarget.innerHTML = ''
  }

  suggestedEmailClicked (event) {
    this.inputTarget.value = event.currentTarget.textContent
    this.clearHint()
    this.showWarning()
  }

  hideWarning () {
    this.warningTarget.classList.add('hidden')
  }

  showWarning () {
    const email = this.inputTarget.value

    if (/^.*@(hotmail|outlook|live)\.*/.test(email)) {
      const domain = email.split('@').pop()
      const text = i18n.t('forms.sign-up.microsoft-email', {
        domain,
        here_link: `<a href='${this.linkValue}' target='_blank'>${i18n.t('forms.sign-up.microsoft-email-here')}</a>`
      })

      this.warningTarget.classList.remove('hidden')
      this.warningTarget.innerHTML = text
    }
  }
}
