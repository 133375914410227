/* global DataTransfer */
import { Controller } from '@hotwired/stimulus'
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static get targets () {
    return ['canvas', 'error', 'fileInput']
  }

  connect () {
    this.signed = false
    this.initializeCanvas()
  }

  initializeCanvas () {
    this.signaturePad = new SignaturePad(this.canvasTarget, {
      backgroundColor: 'rgb(246,246,246)',
      onBegin: this.hideError.bind(this)
    })

    window.addEventListener('resize', this.resizeCanvas.bind(this))
    window.addEventListener('orientationchange', this.resizeCanvas.bind(this))
    this.resizeCanvas()
    this.resizeCanvas()
  }

  resizeCanvas () {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)

    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio
    this.canvasTarget.getContext('2d').scale(ratio, ratio)
    this.clearForm()
  }

  saveSignature (event) {
    if (this.validateDataPoints()) {
      const image = this.signaturePad.toDataURL('image/svg+xml')
      const blob = this._dataURItoBlob(image)
      const file = new File([blob], `${crypto.randomUUID()}.svg`, { type: blob.type })

      this.attachFile(file)
    } else {
      event.preventDefault()
    }
  }

  attachFile (file) {
    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(file)
    this.fileInputTarget.files = dataTransfer.files
  }

  validateDataPoints () {
    let dataPoints = 0
    if (!this.signaturePad.isEmpty()) {
      Object.values(this.signaturePad._data).forEach((value) => {
        dataPoints += value.points.length
      })
    }

    if (dataPoints > 10) {
      this.hideError()
      return true
    } else {
      this.showError()
      return false
    }
  }

  hideError () {
    this.errorTarget.classList.add('hidden')
  }

  showError () {
    this.errorTarget.classList.remove('hidden')
  }

  clearForm () {
    this.signaturePad.clear()
    this.hideError()
  }

  _dataURItoBlob (dataURI) {
    // https://gist.github.com/pbojinov/2c1bcdadebc8a41366ed
    let byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1])
    } else {
      byteString = unescape(dataURI.split(',')[1])
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
  }
}
