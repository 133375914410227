import { Controller } from '@hotwired/stimulus'
import { i18n } from 'i18n'

export default class extends Controller {
  static get targets () {
    return ['container', 'bar']
  }

  resetProgressBar () {
    this.containerTarget.style.display = 'block'
    this.barTarget.classList.remove('bar-error')
    this.barTarget.style.width = '0%'
    this.barTarget.innerHTML = i18n.t('forms.edit-profile.document-loading')
  }

  completed () {
    this.barTarget.innerHTML = i18n.t('forms.edit-profile.document-uploading-done')
  }

  updateBar (event) {
    this.barTarget.style.width = event.params.amount
  }

  updateProgressBar (loaded, total) {
    const progress = parseInt(loaded / total * 100, 10)
    this.barTarget.style.width = `${progress}%`
  }

  failProgressBar () {
    this.containerTarget.style.display = 'block'
    this.barTarget.classList.add('bar-error')
    this.barTarget.style.width = '100%'
    this.barTarget.innerHTML = i18n.t('forms.edit-profile.document-uploading-failed')
  }
}
