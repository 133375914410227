/* global analytics, mixpanel */

import airbrake from 'airbrake'

const initializeAnalytics = () => {
  (function () {
    // Create a queue, but don't obliterate an existing one!
    window.analytics = window.analytics || []
    const analytics = window.analytics

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return analytics

    // If the snippet was invoked already then return
    if (analytics.invoked) return analytics

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware'
    ]

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
      return function (...args) {
        args.unshift(method)
        analytics.push(args)
        return analytics
      }
    }

    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i]
      analytics[key] = analytics.factory(key)
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (key, options) {
      // Create an async script element based on your key.
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://cdn.segment.com/analytics.js/v1/' +
          key + '/analytics.min.js'

      // Insert our script next to the first script element.
      const first = document.getElementsByTagName('script')[0]
      first.parentNode.insertBefore(script, first)
      analytics._loadOptions = options
    }

    // analytics._writeKey = 'YOUR_WRITE_KEY'
    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.15.2B'
  })()

  const loadMixpanel = function (count) {
    if (count <= 3) {
      if (typeof mixpanel !== 'undefined' && typeof mixpanel.get_distinct_id !== 'undefined') {
        window.anonId = mixpanel.get_distinct_id()
        window.userDetails.initial_referring_domain = mixpanel.cookie.properties().$initial_referring_domain
      } else {
        count += 1
        setTimeout(loadMixpanel, 2000, count)
      }
    }
  }

  analytics.ready(function () {
    const userData = document.getElementById('user-data')?.dataset

    if (userData) {
      window.userId = userData.userId
      window.anonId = userData.anonId

      try {
        window.userDetails = JSON.parse(userData.userDetails || '{}')
      } catch (e) {
        window.userDetails = {}
        airbrake.notify(e)
      }

      loadMixpanel(0)
    }
  })

  return analytics
}

export default initializeAnalytics
