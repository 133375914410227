/* global Turbo */

import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static get targets () {
    return ['modal', 'frame', 'previous', 'next']
  }

  static get values () {
    return { show: String }
  }

  connect () {
    if (this.showValue === 'true') {
      this.modal = new Modal(this.modalTarget)
      this.modal.show()
    }
  }

  renderFromUrl (href) {
    this.frameTarget.src = href
    this.frameTarget.reload()
  }

  previousSlide () {
    if (this.hasPreviousTarget) {
      Turbo.visit(this.previousTarget, { frame: this.previousTarget.dataset.frame })
    }
  }

  nextSlide (event) {
    if (this.hasNextTarget) {
      Turbo.visit(this.nextTarget, { frame: this.nextTarget.dataset.frame })
    }
  }
}
