import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['feedback', 'feedbackRadio']

  optionClicked () {
    if (this.hasFeedbackRadioTarget) {
      this.feedbackTarget.disabled = !this.feedbackRadioTarget.checked
    }
  }
}
