import { Modal } from 'bootstrap'
import { i18n } from 'i18n'

const ErrorModal = class {
  constructor () {
    this.template = document.getElementById('error-modal-template')
    this.el = document.createElement('div')
    this.el.classList.add('modal')
  }

  render (content) {
    const clone = this.template.content.cloneNode(true)

    this.el.appendChild(clone)
    this.modal = new Modal(this.el, { backdrop: 'static' })
    this.modal.show()

    document.getElementById('error-modal-title').textContent = content.title
    document.getElementById('error-modal-detail').textContent = content.detail

    if (content.backLink) {
      document.getElementById('error-cta-link').classList.remove('hidden')
      document.querySelector('#error-cta-link a').href = content.backLink
    } else {
      document.getElementById('error-cta-button').classList.remove('hidden')
    }
  }

  renderFatalError (fatalErrorUrl) {
    const content = {
      title: i18n.t('campaign.application-fatal-error.title'),
      detail: i18n.t('campaign.application-fatal-error.detail'),
      backLink: fatalErrorUrl
    }

    this.error(content)
  }

  error (content) {
    this.render(content)
    return this
  }
}

export default ErrorModal
