import { Controller } from '@hotwired/stimulus'
import { i18n } from 'i18n'
import { getStripe } from 'components/stripe'

export default class extends Controller {
  static get targets () {
    return ['cardholderName', 'element', 'error', 'funding', 'token']
  }

  static get values () {
    return {
      creditAllowed: Boolean,
      postal: String
    }
  }

  async connect () {
    this.Stripe = await getStripe()
    await this.mountCardForm()
  }

  disconnect () {
    this.unmountCardForm()
  }

  mountCardForm () {
    this.cardElement = this.Stripe.createCardElement(this.postalValue)
    this.cardElement.mount(this.elementTarget)

    this.cardElement.on('change', (event) => {
      if (event.error) {
        this.errorCallback(event.error.message)
      }
    })
  }

  unmountCardForm () {
    this.cardElement.destroy()
  }

  validateForm () {
    this.clearErrors()

    if (this.elementTarget.classList.contains('StripeElement--empty')) {
      this.errorCallback('Please enter your card number.')
      return false
    } else {
      const event = new Event('validateForm')
      window.dispatchEvent(event)
      return this.element.checkValidity()
    }
  }

  requestPaymentMethodId () {
    this.createStripePaymentMethod(
      this.errorCallback.bind(this)
    )
  }

  validateAndRequestPaymentMethodId (event) {
    if (!this.tokenTarget.value) {
      event.detail.formSubmission.stop()
      this.clearErrors()

      if (this.validateForm()) {
        this.requestPaymentMethodId()
      }
    }
  }

  createStripePaymentMethod (errorCallback) {
    this.Stripe.createPaymentMethod('card', this.cardElement, {
      billing_details: {
        name: this.cardholderNameTarget.value
      }
    }).then((result) => {
      if (result.paymentMethod) {
        const funding = result.paymentMethod.card.funding

        if (this.validateCardType(funding)) {
          this.fundingTarget.value = funding
          this.tokenTarget.value = result.paymentMethod.id
          this.element.requestSubmit()
        } else {
          errorCallback(i18n.t('campaign.purchase-debit-only.detail'))
        }
      }
    })
  }

  validateCardType (funding) {
    if (!this.creditAllowedValue) {
      return funding === 'debit' || funding === 'prepaid'
    }
    return true
  }

  errorCallback (message) {
    this.elementTarget.classList.add('StripeElement--invalid')
    this.errorTarget.innerText = message
  }

  clearErrors () {
    this.errorTarget.innerHTML = null
  }
}
