import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  DoughnutController,
  Legend,
  LinearScale,
  Tooltip
} from 'chart.js'

Chart.register([
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  DoughnutController,
  Legend,
  LinearScale,
  Tooltip])

if (process.env.RAILS_ENV === 'test') {
  Chart.defaults.animation = false
}

Tooltip.positioners.cursor = (chartElements, coordinates) => {
  return coordinates
}

export default Chart
