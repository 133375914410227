import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  reset () {
    this.element.reset()

    this.element.querySelectorAll('select').forEach((element) => {
      if (element.slimSelectInstance) {
        element.slimSelectInstance.setSelected(null)
      }
    })
  }
}
